<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="resetfun">刷新</el-button>
				</li>
			</ul>
		</div>

		<div class="content">
			<div class="title"><span></span>系统统计</div>
			<div class="list">
				<div class="li" v-loading="loading" element-loading-text="数据统计中...">
					<div class="bg">
						<p>总党员数</p>
						<div>{{$store.state.setTotal.party_total || 0}}
						</div>
						<i class="i5 icon iconfont icon-gerenziliao"></i>
					</div>
				</div>
				<div class="li" v-loading="loading" element-loading-text="数据统计中...">
					<div class="bg">
						<p>总用户数</p>
						<div>{{$store.state.setTotal.user_total || 0}}
						</div>
						<i class="i4 icon iconfont icon-jingqingluru"></i>
					</div>
				</div>
				<div class="li" v-loading="loading" element-loading-text="数据统计中...">
					<div class="bg">
						<p>总开通村数</p>
						<div>{{$store.state.setTotal.village_total || 0}}
						</div>
						<i class="i6 icon iconfont icon-rizhi"></i>
					</div>
				</div>
				<div class="li" v-loading="loading" element-loading-text="数据统计中...">
					<div class="bg">
						<p>总订单数</p>
						<div>{{$store.state.setTotal.total || 0}}
						</div>
						<i class="i3 icon iconfont icon-suanfazongshu"></i>
					</div>
				</div>

				<div class="li" v-loading="loading" element-loading-text="数据统计中...">
					<div class="bg">
						<p>村重要人群总数</p>
						<div>{{$store.state.setTotal.village_groups || 0}}
						</div>
						<i class="i2 icon iconfont icon-qiyeyewu"></i>
					</div>
				</div>
				<div class="li" v-loading="loading" element-loading-text="数据统计中...">
					<div class="bg">
						<p>奖补用户申请总数</p>
						<div>{{$store.state.setTotal.user_bonus_total || 0}}
						</div>
						<i class="i1 icon iconfont icon-daohang-shujufenxi"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		ElNotification,
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/index";
	export default {
		data() {
			return {
				tableloading: false,
				loadings: false,
				loading: false,
				timeval: '',
				tableData: [],
				setdata: {},
				imgtoal: '',
				form: {
					page: 1,
					limit: 99999
				},
				tform: {
					start: '',
					end: ''
				},
				setconfig: {},
				type: ''
			}
		},
		created() {
			if (this.$store.state.villageId > 0) {
				this.getinfo()
			}
		},
		methods: {
			// 检查是否完善信息
			getinfo() {
				this.$http.post(api.vinfo, {
						id: this.$store.state.villageId
					})
					.then((res) => {
						if (res.code == 200) {
							if (!res.data.address || !res.data.thumb) {
								var that = this
								ElMessageBox.confirm(
										'请完善村资料',
										'提示', {
											confirmButtonText: '确定',
											cancelButtonText: '取消',
											type: 'warning',
										}
									)
									.then(() => {
										that.$router.replace('/villageInfo')
									})
									.catch(() => {
										// 取消
									})
							}
						}
					})
			},
			// 刷新
			resetfun() {
				this.getstatistics()
			},
			// 获取统计
			getstatistics() {
				this.$http.post(api.statistics)
					.then((res) => {
						// 提交
						this.$store.dispatch('SEtTOTAL', res.data)
					})
			},

		}
	}
</script>

<style lang="scss" scoped>
	.title {
		margin-top: 30px;
		color: #000;
		font-size: 15px;
		width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		padding-left: 10px;

		span {
			height: 12px;
			width: 3px;
			margin-right: 5px;
			background: #00ad79;
			display: inline-block;
		}
	}

	.list {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.li {
			width: 33.333333333%;
			padding: 10px;

			.bg {
				box-shadow: 0 3px 13px 0 rgb(0 0 0 / 11%);
				background-color: #FFFFFF;
				padding: 20px;
				font-size: 14px;
				border-radius: 8px;
				position: relative;

				i {
					position: absolute;
					right: 20px;
					width: 44px;
					height: 44px;
					text-align: center;
					line-height: 44px;
					color: #ffffff;
					top: 50%;
					font-size: 22px;
					margin-top: -22px;
					display: inline-block;
					border-radius: 50%;
				}

				.i0 {
					background: -webkit-linear-gradient(#6887fa, #00ad79);
					background: -o-linear-gradient(#6887fa, #00ad79);
					background: -moz-linear-gradient(#6887fa, #00ad79);
					background: linear-gradient(#6887fa, #00ad79);

				}

				.i1 {
					background: -webkit-linear-gradient(#ffc937, #ffb032);
					background: -o-linear-gradient(#ffc937, #ffb032);
					background: -moz-linear-gradient(#ffc937, #ffb032);
					background: linear-gradient(#ffc937, #ffb032);

				}

				.i2 {
					background: -webkit-linear-gradient(#a9e641, #6bd126);
					background: -o-linear-gradient(#a9e641, #6bd126);
					background: -moz-linear-gradient(#a9e641, #6bd126);
					background: linear-gradient(#a9e641, #6bd126);
				}

				.i3 {
					background: -webkit-linear-gradient(#3facda, #2f8de2);
					background: -o-linear-gradient(#3facda, #2f8de2);
					background: -moz-linear-gradient(#3facda, #2f8de2);
					background: linear-gradient(#3facda, #2f8de2);
				}

				.i4 {
					background: -webkit-linear-gradient(#a75acd, #7c4cc6);
					background: -o-linear-gradient(#a75acd, #7c4cc6);
					background: -moz-linear-gradient(#a75acd, #7c4cc6);
					background: linear-gradient(#a75acd, #7c4cc6);
				}

				.i5 {
					background: -webkit-linear-gradient(#46c8d6, #30b5d4);
					background: -o-linear-gradient(#46c8d6, #30b5d4);
					background: -moz-linear-gradient(#46c8d6, #30b5d4);
					background: linear-gradient(#46c8d6, #30b5d4);
				}

				.i6 {
					background: -webkit-linear-gradient(#ff5b3d, #ff2b67);
					background: -o-linear-gradient(#ff5b3d, #ff2b67);
					background: -moz-linear-gradient(#ff5b3d, #ff2b67);
					background: linear-gradient(#ff5b3d, #ff2b67);
				}

				div {
					margin-top: 15px;
					font-size: 20px;
				}
			}
		}
	}

	.content {
		padding: 20px;
	}
</style>