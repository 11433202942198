import request from './request'

const api = {
	// 统计数量
  mycount: '/statistics/mycount',
  // 今日加班管理用户
  todayovertimework: '/admin/todayovertimework',
  // 获取配置信息
  configinfo: '/config/info',
  // 个人信息
  info: '/admin/my',
  // 统计图片数量
  imgCount: '/statistics/imgCount',
  // 村信息
  vinfo: '/village/read',
  // 获取统计接口
  statistics: '/system/statistics',
}

export { request, api }
